import * as ethers from 'ethers'
import AuthKeyMetaTransaction from './AuthKeyMetaTransaction'
import Address from './Address'

export interface TransactionData {
  transactionHash: string
  from: Address | string
  to: Address | string
  value: string
  data: string
  gasPrice: string
  gasLimit: string
}

export interface ContractTransaction {
  to: Address
  value: string
  data: string
  gasLimit: string
}

export default class Transaction {
  name:string
  transactionHash: string
  from: Address
  to: Address
  value: string
  data: string
  gasPrice: string
  gasLimit: string

  constructor(transactionData: TransactionData) {
    this.name = 'Transaction'
    this.transactionHash = transactionData.transactionHash
    this.from = new Address(transactionData.from)
    this.to = new Address(transactionData.to)
    this.value = transactionData.value
    this.data = transactionData.data
    this.gasPrice = transactionData.gasPrice
    this.gasLimit = transactionData.gasLimit
  }

  decodeContractTransactions(encodedTransactions: string[]): ContractTransaction[] {
    const transactions: ContractTransaction[] = encodedTransactions.map( (encodedTransaction: string): ContractTransaction => {
      let abiCoder = ethers.utils.defaultAbiCoder
      const [
        to,
        value,
        gasLimit,
        data
      ] = abiCoder.decode(
        ['address', 'uint256', 'uint256', 'bytes'],
        encodedTransaction
      )

      return {
        to: new Address(to),
        value: value.toString(),
        gasLimit: gasLimit.toString(),
        data
      }
    })

    return transactions
  }
}