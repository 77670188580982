import React, { FunctionComponent } from 'react'
import AuthKeyMetaTransaction from '../../../models/AuthKeyMetaTransaction'
import LoginKeyMetaTransaction from '../../../models/LoginKeyMetaTransaction'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent'
import  TransactionDetail, {
  TransactionDetailText,
  TransactionDetailEth,
  TransactionDetailAddressLink,
  TransactionDetailHashLink
} from './TransactionDetail'
import  ContractTransactionDetails from './ContractTransactionDetails'
import { SomeTransaction, TopLevelTransaction } from '../../../models/Types'

const useStyles = makeStyles(({ breakpoints }) => createStyles({
  root: {
    textAlign: 'left'
  },
  cardTitle: {
    marginBottom: '3.0rem'
  },
  overview: {
    margin: '1.0rem 0 5.5rem 0'
  },
  transactions: {
    marginBottom: '2.0rem'
  },
  sectionHeader: {
    marginTop: '5.0rem'
  },
  subsectionHeader: {
    margin: '7.0rem 0 5.0rem 0'
  }
}))

interface TransactionDetailsProps {
  transaction?: SomeTransaction
}

const TransactionDetails: FunctionComponent<TransactionDetailsProps> = ({ transaction }) => {
  const styles = useStyles()

  if (!transaction) {
    return (
      <div>
        'Transaction not found'
      </div>
    )
  }

  return (
    <Box className={styles.root}>
      <Typography variant="h1" gutterBottom className={styles.sectionHeader}>
        { transaction.name }
      </Typography>
      <Card>
        <Box className={styles.overview}>
          <Typography variant="h3" gutterBottom className={styles.cardTitle}>
            Overview
          </Typography>
          <TransactionDetailHashLink
            title="Transaction Hash"
            txHash={ transaction.transactionHash }
          />
          <TransactionDetailAddressLink
            title="Relayer"
            address={ transaction.from }
          />
          <TransactionDetailAddressLink
            title="Account Contract"
            address={ transaction.to }
          />
          <TransactionDetailEth title="Value" wei={ transaction.value }/>
          <TransactionDetailText title="Gas Price" value={ transaction.gasPrice } />
          <TransactionDetailText title="Gas Limit" value={ transaction.gasLimit } />
        </Box>
        <Box className={styles.transactions}>
          <Typography variant="h3" gutterBottom className={styles.cardTitle}>
            Authereum Transaction Parameters
          </Typography>
          {
            transaction instanceof AuthKeyMetaTransaction || 
            transaction instanceof LoginKeyMetaTransaction ?
            <TopLevelTransactionDetails
            transaction={ transaction }
            /> :
            <div/>
          }
        </Box>
      </Card>
      {
        transaction instanceof AuthKeyMetaTransaction || 
        transaction instanceof LoginKeyMetaTransaction ?
          <Box>
            <Typography variant="h2" gutterBottom className={styles.subsectionHeader}>
              Contract Transactions
            </Typography>
            {transaction.transactions.map( (contractTransaction, index) =>
              <Card className="ContractTransactionCard" key={index}>
                <CardContent>
                  <ContractTransactionDetails
                    contractTransaction={contractTransaction}
                    index={index}
                  />
                </CardContent>
              </Card>
            )}
          </Box> :
          <div />
      }
    </Box>
  )
}

interface TopLevelTransactionDetailsProps {
  transaction: TopLevelTransaction
}

const TopLevelTransactionDetails: FunctionComponent<TopLevelTransactionDetailsProps> = ({ transaction }) => {
  return (
    <div>
      <TransactionDetailText
        title="Signed Gas Price"
        value={transaction.signedGasPrice}
      />
      <TransactionDetailText
        title="Gas Overhead"
        value={transaction.gasOverhead}
      />
      <TransactionDetailAddressLink
        title="Fee Token Address"
        address={ transaction.feeTokenAddress }
      />
      <TransactionDetailText
        title="Fee Token Rate"
        value={transaction.feeTokenRate}
      />
      <TransactionDetailText
        title="Transaction Message Hash Signature"
        value={transaction.transactionMessageHashSignature}
      />
      {
        transaction instanceof LoginKeyMetaTransaction ?
          <LoginKeyMetaTransactionDetails
            transaction={ transaction }
          /> :
          <div />
      }
    </div>
  )
}

interface LoginKeyMetaTransactionDetailsProps {
  transaction: LoginKeyMetaTransaction
}

const LoginKeyMetaTransactionDetails: FunctionComponent<LoginKeyMetaTransactionDetailsProps> = ({ transaction }) => {
  return (
    <div>
      <TransactionDetailText
        title="Restriction Data"
        value={transaction.restrictionData}
      />
      <TransactionDetailText
        title="Login Key Attestation Signature"
        value={transaction.loginKeyAttestationSignature}
      />
    </div>
  )
}

export default TransactionDetails