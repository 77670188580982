import React from 'react'
import './App.css'
import { makeStyles, createStyles } from '@material-ui/core/styles'
// import { Theme } from '@material-ui/core/styles/createMuiTheme'
import TransactionPage from './components/pages/TransactionPage/TransactionPage'
import Header from './components/common/Header'
import HomePage from './components/pages/HomePage/HomePage'
import ComponentPage from './components/pages/ComponentPage/ComponentPage'
import NoTransactionPage from './components/pages/NoTransactionPage/NoTransactionPage'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom'
import {
  ThemeProvider,
  createMuiTheme,
  ThemeOptions
} from '@material-ui/core/styles'

import theme from './theme'

const useStyles = makeStyles(({ breakpoints }) => createStyles({
  root: {
    background: '#F7F8F9',
    fontSize: '1rem',
    padding: '3.5rem',
    [breakpoints.down('xs')]: {
      background: '#FFFFFF'
    }
  }
}))

function App() {
  const styles = useStyles()

  return (
    <Router>
      <ThemeProvider theme={ theme }>
        <div className={styles.root}>
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />

          <Header />

          <Switch>
            <Route path="/" exact={true} component={ HomePage } />
            <Route path="/tx" exact={true} component={ NoTransactionPage } />
            <Route path="/tx/:txHash" component={ TransactionPage } />
            <Route path="/components"  component={ ComponentPage } />
          </Switch>
        </div>
      </ThemeProvider>
    </Router>
  )
}

export default App
