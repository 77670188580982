import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import * as ethers from 'ethers'

import Address from '../../../models/Address'

const useStyles = makeStyles(() => ({
  root: {
    margin: '1.0rem 0',
    display: 'flex',
    flexDirection: 'row'
  },
  label: {
    flex: '20%',
    paddingRight: '2.0rem'
  },
  value: {
    flex: '80%',
    wordBreak: 'break-all'
  },
  valueDetail: {
    wordBreak: 'break-word',
    marginLeft: '1.4rem'
  }
}))

type TransactionDetailProps = {
  title: string
}

const TransactionDetail: FunctionComponent<TransactionDetailProps> = ({ title, children }) => {
  const styles = useStyles()

  return (
    <Box className={styles.root}>
      <Box className={styles.label}>
        <Typography variant="subtitle1" color="secondary">
          { title }
        </Typography>
      </Box>
      <Box className={styles.value}>
        { children }
      </Box>
    </Box>
  )
}

interface TransactionDetailTextProps {
  title: string,
  value: string
}

export const TransactionDetailText: FunctionComponent<TransactionDetailTextProps> = ({ title, value }) => {
  return (
    <TransactionDetail title={title}>
      <Typography variant="subtitle2">
        { value }
      </Typography>
    </TransactionDetail>
  )
}

interface TransactionDetailEthProps {
  title: string,
  wei: string
}

export const TransactionDetailEth: FunctionComponent<TransactionDetailEthProps> = ({ title, wei }) => {
  const transactionValue = ethers.utils.formatEther(wei)

  return (
    <TransactionDetail title={title}>
      <Typography variant="subtitle2">
        { `${transactionValue} ETH` }
      </Typography>
    </TransactionDetail>
  )
}

interface TransactionDetailAddressLinkProps {
  title: string,
  address: Address
}

export const TransactionDetailAddressLink: FunctionComponent<TransactionDetailAddressLinkProps> = ({ title, address }) => {
  const styles = useStyles()

  console.log('title: ', title)
  console.log('address: ', address)
  const addressName = address.name()
  return (
    <TransactionDetailLink
      title={ title }
      href={`https://etherscan.io/address/${address}`}
    >
      <Typography variant="subtitle2">
        { address.toString() }
        {
          addressName ?
          <Box
            display="inline"
            fontWeight="fontWeightRegular"
            className={styles.valueDetail}
          >
            { `(${addressName})`}
          </Box> :
          <></>
        }
      </Typography>
    </TransactionDetailLink>
  )
}

interface TransactionDetailHashLinkProps {
  title: string,
  txHash: string
}

export const TransactionDetailHashLink: FunctionComponent<TransactionDetailHashLinkProps> = ({ title, txHash }) => {
  return (
    <TransactionDetailLink
      title={ title }
      href={`https://etherscan.io/tx/${txHash}`}
    >
      <Typography variant="subtitle2">
        {txHash}
      </Typography>
    </TransactionDetailLink>
  )
}

interface TransactionDetailLinkProps {
  title: string,
  href: string
}

export const TransactionDetailLink: FunctionComponent<TransactionDetailLinkProps> = ({ title, href, children }) => {
  return (
    <TransactionDetail title={title}>
      <Link href={href}>
        { children }
      </Link>
    </TransactionDetail>
  )
}

export default TransactionDetail