import * as ethers from 'ethers'
import Transaction, { TransactionData, ContractTransaction } from './Transaction'
import Address from './Address'

export default class AuthKeyMetaTransaction extends Transaction {
  signedGasPrice: string
  gasOverhead: string
  feeTokenAddress: Address
  feeTokenRate: string
  transactionMessageHashSignature: string
  transactions: ContractTransaction[]

  constructor(transactionData: TransactionData) {
    super(transactionData)

    this.name = 'Auth Key Meta Transaction'

    const data = transactionData.data
    const functionSelector = data.substring(0, 10)
    const parameterData = '0x' + data.substring(10, data.length)

    let abiCoder = ethers.utils.defaultAbiCoder
    let [
      encodedTransactions,
      gasPrice,
      gasOverhead,
      feeTokenAddress,
      feeTokenRate,
      transactionMessageHashSignature
    ] = abiCoder.decode(
      ['bytes[]', 'uint256', 'uint256', 'address', 'uint256', 'bytes'],
      parameterData
    )

    this.signedGasPrice = gasPrice.toString()
    this.gasOverhead = gasOverhead.toString()
    this.feeTokenAddress = new Address(feeTokenAddress)
    this.feeTokenRate = feeTokenRate.toString()
    this.transactionMessageHashSignature = transactionMessageHashSignature
    this.transactions = this.decodeContractTransactions(encodedTransactions)
  }
}