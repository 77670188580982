import React, { FunctionComponent } from 'react'
import HeroMessage from '../../common/HeroMessage'

const NoTransactionPage: FunctionComponent<{}> = () => {
  return (
    <HeroMessage
      message="No transaction found. Please check your network and the provided transaction hash."
    />
  )
}

export default NoTransactionPage