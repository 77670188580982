import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { ContractTransaction } from '../../../models/Transaction'
import  { TransactionDetailText, TransactionDetailEth, TransactionDetailAddressLink } from './TransactionDetail'

interface ContractTransactionDetailsProps {
  contractTransaction: ContractTransaction,
  index: number
}

const useStyles = makeStyles(() => ({
  cardTitle: {
    marginBottom: '3.0rem'
  }
}))

const ContractTransactionDetails: FunctionComponent<ContractTransactionDetailsProps> = ({ contractTransaction, index }) => {
  const styles = useStyles()

  return (
    <div key={ index } className="ContractTransaction">
      <Typography variant="h3" className={styles.cardTitle}>
        Transaction {index}
      </Typography>
      <div className="ContractTransactionDetails">
        <TransactionDetailAddressLink
          title="To"
          address={ contractTransaction.to }
        />
        <TransactionDetailEth title="value" wei={ contractTransaction.value }/>
        <TransactionDetailText title="Gas Limit" value={ contractTransaction.gasLimit }/>
        <TransactionDetailText title="Data" value={ contractTransaction.data }/>
      </div>
    </div>
  )
}

export default ContractTransactionDetails