import { addresses } from '../constants'
import normalizeHex from 'normalize-hex'
import toChecksumAddress from '@authereum/utils/core/toChecksumAddress'

type Addressish = Address | string

export default class Address {
  readonly address: string
  private _name?: string

  static isAddress(maybeAddress: Addressish) {
    if (maybeAddress instanceof Address) {
      return true
    }

    try {
      new Address(maybeAddress)
    } catch (_) {
      return false
    }
    
    return true
  }

  constructor(address: Addressish) {
    this.address = toChecksumAddress(address.toString())
  }

  public name() {
    if (!this._name) {
      const addressMetaData = addresses.find( adr =>
        this.eq(adr.address)
      )
      if (addressMetaData) {
        this._name = addressMetaData.name
      }
    }

    return this._name
  }

  toString() {
    return this.address
  }

  toLowerCase() {
    return this.address.toLowerCase()
  }

  eq(address: Addressish) {
    let temp: Address

    if (address instanceof Address) {
      temp = address
    } else {
      try {
        temp = new Address(address)
      } catch (_) {
        return false
      }
    }

    return this.address === temp.address
  }
}