import * as ethers from 'ethers'
import Transaction, { TransactionData } from './Transaction'
import AuthKeyMetaTransaction from './AuthKeyMetaTransaction'
import LoginKeyMetaTransaction from './LoginKeyMetaTransaction'
import { SomeTransaction } from './Types'
import { network } from '../config'

export default class TransactionLoader {
  static async loadTransaction(transactionHash: string): Promise<SomeTransaction> {
    const provider = new ethers.providers.JsonRpcProvider(`https://${network}.infura.io/v3/603c79ce8cc34b9aba08aaa0b7713891`)

    const tx = await provider.getTransaction(transactionHash)
    const receipt = await provider.getTransactionReceipt(transactionHash)

    if (!tx) {
      throw new Error('Transaction not found')
    }

    const functionSelector = TransactionLoader.parseFunctionSelector(tx.data)
    const transactionData: TransactionData = {
      transactionHash,
      from: tx.from,
      to: tx.to ? tx.to : 'N/A',
      value: tx.value.toString(),
      data: tx.data,
      gasPrice: tx.gasPrice.toString(),
      gasLimit: tx.gasLimit.toString()
    }

    if (functionSelector == '0x26aa4655') {
      return new AuthKeyMetaTransaction(transactionData)
    } else if (functionSelector == '0xd5553b88') {
      return new LoginKeyMetaTransaction(transactionData)
    } else {
      return new Transaction(transactionData)
    }
  }

  private static parseFunctionSelector(data: string): string | undefined {
    let functionSelector = '0x'
    if (data && data.length >= 10) {
      functionSelector = data.substring(0, 10)
    }
    return functionSelector
  }
}