import React, { FunctionComponent, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import normalizeHex from 'normalize-hex'
import {
  useHistory
} from 'react-router-dom'

import logo from '../../assets/authereum.svg'

const useStyles = makeStyles(({ breakpoints }) => createStyles({
  root: {
    height: '8.0rem',
    [breakpoints.down('xs')]: {
      height: 'inherit'
    }
  },
  imgContainer: {
    position: 'relative',
  },
  img: {
    height: '3.4rem',
    position: 'absolute',
    top: '50%',
    // '-ms-transform': 'translateY(-50%)',
    transform: 'translateY(-50%)'
  },
  searchTextField: {
    width: '20.0rem'
  },
  searchForm: {
    display: 'inline-block',
    width: '25.0rem',
    borderRadius: '0.9rem',
    padding: '1.0rem 1.4rem',
    transition: 'background-color 0.3s ease',
    "&:hover, &:focus": {
      backgroundColor: '#F0F1F3'
    },
    [breakpoints.down('xs')]: {
      backgroundColor: '#FAFAFB'
    }
  },
  searchContainer: {
    textAlign: 'right',
    [breakpoints.down('xs')]: {
      textAlign: 'left'
    }
  }
}))

const Header: FunctionComponent<{}> = () => {
  const [searchString, setSearchString] = useState('')

  const history = useHistory()
  const handleSearch = (e: any) => {
    e.preventDefault()
    const maybeTxHash = normalizeHex(searchString, { addPrefix: true })
    console.log('maybeTxHash: ', maybeTxHash)
    if (!maybeTxHash || maybeTxHash.length != 66) {
      history.push('/tx')
    } else {
      history.push(`/tx/${maybeTxHash}`)
    }
  }

  const styles = useStyles();
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={styles.root}
      spacing={5}
    >
      <Grid item sm={6} xs={12}>
        <a href="/" className={styles.imgContainer}>
          <img className={styles.img} src={logo} />
        </a>
      </Grid>
      <Grid item sm={6} xs={12} className={styles.searchContainer}>
        <form onSubmit={handleSearch} className={styles.searchForm}>
          <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item>
              <SearchIcon />
            </Grid>
            <Grid item>
              <TextField
                id="search"
                placeholder="Search by transaction hash"
                value={searchString}
                onChange={e => setSearchString(e.target.value)}
                className={styles.searchTextField}
                InputProps={{
                  disableUnderline: true
                }}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

export default Header