const addresses = [
  // Common contracts
  {
    address: "0x91b052E4800DB77d4d0BFE9fe2bE48352695F282",
    name: "Erasure: Feed_Factory"
  },
  {
    address: "0x57EB544cCA126D356FFe19D732A79Db494ba09b1",
    name: "Erasure: CountdownGriefingEscrow_Factory"
  },
  {
    address: "0x789D0082B20A929D6fB64EB4c10c68e827AAB7aB",
    name: "Erasure: Erasure_Users"
  },
  {
    address: "0x932279aE5635748A696019488485591089fDB1f0",
    name: "Pooled CDAI"
  },
  {
    address: "0x7Be8076f4EA4A4AD08075C2508e481d6C946D12b",
    name: "Wyvern Exchange"
  },
  {
    address: "0x79a8C46DeA5aDa233ABaFFD40F3A0A2B1e5A4F27",
    name: "Curve.Fi"
  },
  {
    address: "0x76E2cFc1F5Fa8F6a5b3fC4c8F4788F0116861F9B",
    name: "Gnosis Safe: Proxy Factory 1.1.1"
  },


  // Relayers
  {
    address: "0x06A65bEafC02635bf85aa2FC3B86A7F236c0ae9D",
    name: "Authereum Relayer 0"
  },
  {
    address: "0x84aBea9c66d30d00549429F5f687e16708aA20C0",
    name: "Authereum Relayer 1"
  },
  {
    address: "0xA7c0946DbFe11ac8FcC2df3c588Dad7818708f61",
    name: "Authereum Relayer 2"
  },
  {
    address: "0xa50CCC944dA2c59C23F484BDFcE09abF9577c1F1",
    name: "Authereum Relayer 3"
  },
  {
    address: "0x7A54EB72c9a9Cfa4a039E1DaE73317C4BD6d822f",
    name: "Authereum Relayer 4"
  },
  {
    address: "0x61369C6C30eE29EBaef97f2601c9F14d86aDA469",
    name: "Authereum Relayer 5"
  },
  {
    address: "0xE5cC95202572Fa429948FA0FA65F3da11476a6cf",
    name: "Authereum Relayer 6"
  },
  {
    address: "0xe4662E6460cc8F866bF87d696124163e330CA323",
    name: "Authereum Relayer 7"
  },
  {
    address: "0xcb5d8f2B12279F188F007da0Bef2B1F3e0E4224a",
    name: "Authereum Relayer 8"
  },
  {
    address: "0x4eD63E3F54e5aE38cAd009B108e7C7C8f3300d42",
    name: "Authereum Relayer 9"
  },
  {
    address: "0x0407F80e9F3473bba4f55271C7b236EE81Ed24B4",
    name: "Authereum Relayer 10"
  },
  {
    address: "0x5DB9deD487437E0A96391b704885D547756eFe68",
    name: "Authereum Relayer 11"
  },
  {
    address: "0xc39db8eA388b534b7892F0c82F9D32b22283fc7a",
    name: "Authereum Relayer 12"
  },
  {
    address: "0x5a7a4838043285C5dCFFc234Ac77141968c558B7",
    name: "Authereum Relayer 13"
  },
  {
    address: "0xB1571B981BC888a171EAA5c7Ad470c4e24B91aA5",
    name: "Authereum Relayer 14"
  },
  {
    address: "0x463FCeA4A64D3D78bEff934fcDF7999930FD093b",
    name: "Authereum Relayer 15"
  },
  {
    address: "0x3B44a94C5cC59f13e6e1Bf7d021a908C4C993344",
    name: "Authereum Relayer 16"
  },
  {
    address: "0xB37D85cD1eE48cf525C9A266F868E959d22B4cCe",
    name: "Authereum Relayer 17"
  },
  {
    address: "0xf1d37b75Da9755d2e1Ac90e71299821ffdD45017",
    name: "Authereum Relayer 18"
  },
  {
    address: "0x430129B092650eFE25F352B1D169c53b92192479",
    name: "Authereum Relayer 19"
  },

  // Tokens
  {
    address: "0xD0D6D6C5Fe4a677D343cC433536BB717bAe167dD",
    name: "AdToken"
  },
  {
    address: "0x0D8775F648430679A709E98d2b0Cb6250d2887EF",
    name: "Basic Attention Token"
  },
  {
    address: "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
    name: "Binance USD"
  },
  {
    address: "0x107c4504cd79C5d2696Ea0030a8dD4e92601B82e",
    name: "Bloom Token"
  },
  {
    address: "0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C",
    name: "Bancor Network Token"
  },
  {
    address: "0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643",
    name: "Compound Dai"
  },
  {
    address: "0xF5DCe57282A584D2746FaF1593d3121Fcac444dC",
    name: "Compound Sai"
  },
  {
    address: "0x41e5560054824eA6B0732E656E3Ad64E20e94E45",
    name: "Civic"
  },
  {
    address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
    name: "Dai Stablecoin"
  },
  {
    address: "0x4946Fcea7C692606e8908002e55A582af44AC121",
    name: "FOAM Token"
  },
  {
    address: "0x419D0d8BdD9aF5e606Ae2232ed285Aff190E711b",
    name: "FunFair"
  },
  {
    address: "0x543Ff227F64Aa17eA132Bf9886cAb5DB55DCAddf",
    name: "DAOstack"
  },
  {
    address: "0x6810e776880C02933D47DB1b9fc05908e5386b96",
    name: "Gnosis Token"
  },
  {
    address: "0xa74476443119A942dE498590Fe1f2454d7D4aC0d",
    name: "Golem"
  },
  {
    address: "0x12B19D3e2ccc14Da04FAe33e63652ce469b3F2FD",
    name: "GRID Token"
  },
  {
    address: "0x14094949152EDDBFcd073717200DA82fEd8dC960",
    name: "bZx DAI iToken"
  },
  {
    address: "0x818Fc6C2Ec5986bc6E2CBf00939d90556aB12ce5",
    name: "Kin"
  },
  {
    address: "0xdd974D5C2e2928deA5F71b9825b8b646686BD200",
    name: "Kyber Network Crystal"
  },
  {
    address: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
    name: "ChainLink Token"
  },
  {
    address: "0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD",
    name: "LoopringCoin V2"
  },
  {
    address: "0x6c6EE5e31d828De241282B9606C8e98Ea48526E2",
    name: "HoloToken"
  },
  {
    address: "0xC2cB1040220768554cf699b0d863A3cd4324ce32",
    name: "iearn DAI v3"
  },
  {
    address: "0x26EA744E5B887E5205727f55dFBE8685e3b21951",
    name: "iearn USDC v3"
  },
  {
    address: "0xE6354ed5bC4b393a5Aad09f21c46E101e692d447",
    name: "iearn USDT v3"
  },
  {
    address: "0x04bC0Ab673d88aE9dbC9DA2380cB6B79C4BCa9aE",
    name: "iearn BUSD v3"
  },
  {
    address: "0x16de59092dAE5CcF4A1E6439D611fd0653f0Bd01",
    name: "iearn DAI v2"
  },
  {
    address: "0xd6aD7a6750A7593E092a9B218d66C0A814a3436e",
    name: "iearn USDC v2"
  },
  {
    address: "0xF61718057901F84C4eEC4339EF8f0D86D2B45600",
    name: "iearn SUSD v2"
  },
  {
    address: "0x73a052500105205d34daf004eab301916da8190f",
    name: "iearn TUSD v2"
  },
  {
    address: "0x04Aa51bbcB46541455cCF1B8bef2ebc5d3787EC9",
    name: "iearn wBTC v2"
  },
  {
    address: "0xD29F0b5b3F50b07Fe9a9511F7d86F4f4bAc3f8c4",
    name: "Liquidity.Network Token"
  },
  {
    address: "0xA4e8C3Ec456107eA67d3075bF9e3DF3A75823DB0",
    name: "LoomToken"
  },
  {
    address: "0x58b6A8A3302369DAEc383334672404Ee733aB239",
    name: "LivePeer Token"
  },
  {
    address: "0x0F5D2fB29fb7d3CFeE444a200298f468908cC942",
    name: "Decentraland MANA"
  },
  {
    address: "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
    name: "Matic Token"
  },
  {
    address: "0x80f222a749a2e18Eb7f676D371F19ad7EFEEe3b7",
    name: "Magnolia Token"
  },
  {
    address: "0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2",
    name: "Maker"
  },
  {
    address: "0xec67005c4E498Ec7f55E092bd1d35cbC47C91892",
    name: "Melon Token"
  },
  {
    address: "0xB62132e35a6c13ee1EE0f84dC5d40bad8d815206",
    name: "Nexo"
  },
  {
    address: "0x1776e1F26f98b1A5dF9cD347953a26dd3Cb46671",
    name: "Numeraire"
  },
  {
    address: "0xD56daC73A4d6766464b38ec6D91eB45Ce7457c44",
    name: "Panvala pan"
  },
  {
    address: "0x89d24A6b4CcB1B6fAA2625fE562bDD9a23260359",
    name: "Sai Stablecoin"
  },
  {
    address: "0x5e74C9036fb86BD7eCdcb084a0673EFc32eA31cb",
    name: "Synth sETH"
  },
  {
    address: "0x0000000000085d4780B73119b644AE5ecd22b376",
    name: "TrueUSD"
  },
  {
    address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    name: "USD Coin"
  },
  {
    address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    name: "Tether"
  },
  {
    address: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
    name: "Wrapper BTC"
  },
  {
    address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    name: "Wrapper Ether"
  },
  {
    address: "0xE41d2489571d322189246DaFA5ebDe1F4699F498",
    name: "0x Protocol Token"
  }
]

export default addresses


