import React, { useState, useEffect, FunctionComponent } from 'react';
import { useParams } from 'react-router-dom'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Transaction from '../../../models/Transaction'
import TransactionLoader from '../../../models/TransactionLoader'
import  TransactionDetails from './TransactionDetails'

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    textAlign: 'center'
  },
  container: {
    padding: 0
  }
}))

const TransactionPage: FunctionComponent<{}> = () => {
  const styles = useStyles()
  const { txHash } = useParams()
  console.log('txHash: ', txHash)

  const [loadingTransaction, setLoadingTransaction] = useState<boolean>(true)
  const [transaction, setTransaction] = useState<Transaction | undefined>(undefined)

  // Load transaction if not loaded
  useEffect(() => {
    if (!txHash || txHash.length != 66) {
      console.error('Missing or invalid transaction-hash query param')
    } else if (!transaction || transaction.transactionHash != txHash) {
      loadTransaction(txHash)
    }
  })

  const loadTransaction = async (transactionHash: string) => {
    setTransaction(undefined)
    setLoadingTransaction(true)

    const tx = await TransactionLoader.loadTransaction(transactionHash)

    setTransaction(tx)
    setLoadingTransaction(false)
  }

  return (
    <Box className={styles.root}>
      <Container maxWidth="md" className={styles.container}>
        {
          loadingTransaction ?
            <CircularProgress /> :
            <TransactionDetails transaction={ transaction } />
        }
      </Container>
    </Box>
  )
}

export default TransactionPage